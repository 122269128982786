import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";
const api = new APICore();

function getVatDetails(params: any): any {
  const { clientId, accountantAssignId, year } = params?.data;
  return api.get(
    `${URL.GET_VAT_DETAILS}clientId=${clientId}&accountantAssignId=${accountantAssignId}&year=${year}`
  );
}

function addVatDetails(params: any): any {
  const { data } = params;
  return api.create(URL.ADD_VAT_DETAILS, data);
}

function addFinalVatDetails(params: any): any {
  const { data } = params;
  return api.update(URL.ADD_FINAL_VAT_DETAILS, data);
}

export { getVatDetails, addVatDetails, addFinalVatDetails };
