// @flow
import { BusinessEmailActionTypes } from './constants';

export const businessEmailActions = (data) => ({
    type: BusinessEmailActionTypes.BUSINESS_EMAIL_FIRST,
    data,
});

export const businessEmailVerifyActions = (data) => ({
    type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_FIRST,
    data,
});

