import { BusinessEmailActionTypes } from './constants';

const BUSINESS_EMAIL_INITIAL_STATE = {
    BusinessEmailActionTypes: [],
    loading: false,
};


const businessEmailReducer = (
    state = BUSINESS_EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BusinessEmailActionTypes.BUSINESS_EMAIL_LOADING:
            return {
                BusinessEmailActionTypes: state.BusinessEmailActionTypes,
                loading: true,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_SUCCESS:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_ERROR:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_RESET:
            return BUSINESS_EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};

const businessEmailVerifyReducer = (
    state = BUSINESS_EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_LOADING:
            return {
                BusinessEmailActionTypes: state.BusinessEmailActionTypes,
                loading: true,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_SUCCESS:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_ERROR:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_RESET:
            return BUSINESS_EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    businessEmailReducer,
    businessEmailVerifyReducer
}
