import { APICore } from "../../helpers/api/apiCore";
import * as URL from "../../helpers/api/apiEndPoint";

const api = new APICore();

export function geTicketApi(params) {
  const { status, page, limit } = params;

  return api.get(
    `${URL.GET_TICKET}${params?.status}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }`
  );
}

export function getTicketByIdApi(params) {
  return api.get(`${URL.GET_TICKET_BY_ID}/${params?.ticketId}`);
}

export function createTicketApi(params) {
  return api.create(`${URL.CREATE_TICKET}`, params);
}

export function updateReplyTicketApi(params) {
  const { data } = params;
  return api.update(`${URL.UPDATE_TICKET}`, params);
}
