import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function businessEmailEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.BUSINESS_EMAIL_OTP, data);
}
function businessEmailVerifyEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.EMAIL_VERIFY_ON_ADD_DETAILS, data);
}


export {
    businessEmailEndPoint,
    businessEmailVerifyEndPoint
};
