import { VatDetailsActionTypes } from "./constants";

export const getVatDetailsActions = (data): LayoutAction => ({
  type: VatDetailsActionTypes.GET_VAT_DETAILS_FIRST,
  data,
});

export const addVatDetailsActions = (data): LayoutAction => ({
  type: VatDetailsActionTypes.ADD_VAT_DETAILS_FIRST,
  data,
});

export const addFinalVatDetailsActions = (data): LayoutAction => ({
  type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_FIRST,
  data,
});
