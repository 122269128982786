import { RatingTypes } from "./constant";

const Initial_State = {
    data: [],
    loading: false,
    message: ''
};


const addRatingReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case RatingTypes.ADD_RATING_LOADING:
            return {
                data: state.data,
                loading: true,
            };
        case RatingTypes.ADD_RATING_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case RatingTypes.ADD_RATING_ERROR:
            return {
                data: action.payload,
                loading: false,
            };
        case RatingTypes.ADD_RATING_RESET:
            return Initial_State;
        default:
            return state;
    }
};

const getRatingReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case RatingTypes.GET_RATING_LOADING:
            return {
                data: state.data,
                loading: true,
            };
        case RatingTypes.GET_RATING_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case RatingTypes.GET_RATING_ERROR:
            return {
                data: action.payload,
                loading: false,
            };
        case RatingTypes.GET_RATING_RESET:
            return Initial_State;
        default:
            return state;
    }
};

export {
    addRatingReducer,
    getRatingReducer
}
