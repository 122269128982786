import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

function getSearchAccountantListEndPoint(params) {
  const { skill, experience, page, limit } = params?.data;
  return api.get(
    `${URL.GET_SEARCH_ACCOUNTANT_LIST}skillId=${skill}&yearsOfExperienced=${experience}&page=${page}&limit=${limit}`
  );
}
function ignoreClientApiEndPoint(data): any {
  return api.update(`${URL.IGNORE_CLIENT_REQUEST}`, data?.payload);
}
function getMeetingStatusDetailsApiEndPoint(params: any): any {
  return api.get(URL.GET_MEETING_DETAILS_STATUS);
}
export { getSearchAccountantListEndPoint, ignoreClientApiEndPoint, getMeetingStatusDetailsApiEndPoint };
