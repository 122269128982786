import { DashboardActionTypes } from './constants';

const GET_ACCOUNTANT_CLIENT_DASHBOARD_INITIAL_STATE = {
    getAccountantClient: [],
    loading: false,
    message: "",
};

const initial_state = {
    data: [],
    message: "",
    loading: false,
};



export const getAccountantClientReducer = (state = GET_ACCOUNTANT_CLIENT_DASHBOARD_INITIAL_STATE, action) => {
    switch (action.type) {
        case DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_LOADING:
            return {
                getAccountantClient: GET_ACCOUNTANT_CLIENT_DASHBOARD_INITIAL_STATE.getAccountantClient,
                loading: true,
            };
        case DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_SUCCESS:
            return {
                getAccountantClient: action?.payload,
                loading: false,
            };
        case DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_ERROR:
            return {
                getAccountantClient: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};


export const getClientInvitationListReducer = (state = initial_state, action) => {
    switch (action.type) {
        case DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export const acceptClientInvitationReducer = (state = initial_state, action) => {
    switch (action.type) {
        case DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const getDocusignTokenReducers = (state = initial_state, action) => {
    switch (action.type) {
        case DashboardActionTypes.GET_LOADING_DOCUSIGN_API_KEY:
            return {
                data: initial_state.data,
                loading: true,
            };
        case DashboardActionTypes.GET_SUCCESS_DOCUSIGN_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        case DashboardActionTypes.GET_ERROR_DOCUSIGN_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        // case DashboardActionTypes.GET_RESET_DOCUSIGN_API_KEY:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return state;
    }
};

export const getContractListReducer = (state = initial_state, action) => {
    switch (action.type) {
        case DashboardActionTypes.GET_LOADING_CONTRACT_LIST:
            return {
                data: initial_state.data,
                loading: true,
            };
        case DashboardActionTypes.GET_SUCCESS_CONTRACT_LIST:
            return {
                data: action.payload,
                loading: false,
            };
        case DashboardActionTypes.GET_ERROR_CONTRACT_LIST:
            return {
                data: action.payload,
                loading: false,
            };
        // case DashboardActionTypes.GET_RESET_CONTRACT_LIST:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return state;
    }
};


export const getClientContractContractReducer = (state = initial_state, action) => {
    switch (action.type) {
        case DashboardActionTypes.GET_LOADING_CLIENT_CONTRACT_LIST:
            return {
                data: initial_state.data,
                loading: true,
            };
        case DashboardActionTypes.GET_SUCCESS_CLIENT_CONTRACT_LIST:
            return {
                data: action?.payload,
                loading: false,
            };
        // case DashboardActionTypes.GET_RESET_CLIENT_CONTRACT_LIST:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        case DashboardActionTypes.GET_ERROR_CLIENT_CONTRACT_LIST:
            return {
                data: state,
                loading: false,
            };
        case DashboardActionTypes.GET_ERROR_CLIENT_CONTRACT_LIST:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};
