export const RatingTypes = {

    GET_RATING_FIRST: "@@auth/GET_RATING_FIRST",
    GET_RATING_LOADING: "@@auth/GET_RATING_LOADING",
    GET_RATING_SUCCESS: "@@auth/GET_RATING_SUCCESS",
    GET_RATING_ERROR: "@@auth/GET_RATING_ERROR",
    GET_RATING_RESET: "@@auth/GET_RATING_RESET",

    ADD_RATING_FIRST: "@@auth/ADD_RATING_FIRST",
    ADD_RATING_LOADING: "@@auth/ADD_RATING_LOADING",
    ADD_RATING_SUCCESS: "@@auth/ADD_RATING_SUCCESS",
    ADD_RATING_ERROR: "@@auth/ADD_RATING_ERROR",
    ADD_RATING_RESET: "@@auth/EMAIL_VERIFY_SEND_RESET",

};
