export const ticket = {
  GET_TICKET: "GET_TICKET",
  GET_TICKET_LOADING: "GET_TICKET_LOADING",
  GET_TICKET_SUCCESS: "GET_TICKET_SUCCESS",
  GET_TICKET_ERROR: "GET_TICKET_ERROR",
  GET_TICKET_RESET: "GET_TICKET_RESET",

  GET_TICKET_BY_ID: "GET_TICKET_BY_ID",
  GET_TICKET_BY_ID_LOADING: "GET_TICKET_BY_ID_LOADING",
  GET_TICKET_BY_ID_SUCCESS: "GET_TICKET_BY_ID_SUCCESS",
  GET_TICKET_BY_ID_ERROR: "GET_TICKET_BY_ID_ERROR",
  GET_TICKET_BY_ID_RESET: "GET_TICKET_BY_ID_RESET",

  CREATE_TICKET: "CREATE_TICKET",
  CREATE_TICKET_LOADING: "CREATE_TICKET_LOADING",
  CREATE_TICKET_SUCCESS: "CREATE_TICKET_SUCCESS",
  CREATE_TICKET_ERROR: "CREATE_TICKET_ERROR",
  CREATE_TICKET_RESET: "CREATE_TICKET_RESET",

  UPDATE_REPLY_TICKET: "UPDATE_REPLY_TICKET",
  UPDATE_REPLY_TICKET_LOADING: "UPDATE_REPLY_TICKET_LOADING",
  UPDATE_REPLY_TICKET_SUCCESS: "UPDATE_REPLY_TICKET_SUCCESS",
  UPDATE_REPLY_TICKET_ERROR: "UPDATE_REPLY_TICKET_ERROR",
  UPDATE_REPLY_TICKET_RESET: "UPDATE_REPLY_TICKET_RESET",
};
