import React, { useState, useRef, useEffect } from "react";
import { ScreenCapture } from "react-screen-capture";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpSupport from "../Modal/Help&Support";
import { useSelector } from "react-redux";
// import { Loading } from '../loading/Loading';

const CroppedScreenShot = ({ color, height }) => {
  const store = useSelector((state) => state);

  const [capturing, setCapturing] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showCard, setShowCard] = useState(false); // State to control the visibility of the card
  const cardRef = useRef(null);
  const user = store?.Auth?.user?.role;
  const status = store?.createSupportReducer?.createSupport?.status;

  const handleCapture = (uri) => {
    // Set captured image URI
    setCapturedImage(uri);

    // Reset capturing state
    setCapturing(true);

    // Show the card
    setShowCard(true);
  };

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setShowCard(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const currentPageURL = window.location.href;
  const currentPageName = currentPageURL
    .substring(currentPageURL.lastIndexOf("/") + 1)
    .replace(/\b\w/g, (c) => c.toUpperCase());

  const handleDownload = () => {
    if (capturedImage) {
      const currentPageURL = window.location.href;
      const currentPageName = currentPageURL
        .substring(currentPageURL.lastIndexOf("/") + 1)
        .replace(/\b\w/g, (c) => c.toUpperCase());

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = capturedImage;
      link.download = `${currentPageName}_screenshot.png`;

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    }
  };

  const handleCancel = () => {
    // Hide the card
    setShowCard(false);
  };

  const [showHelpAndSupportModal, setshowHelpAndSupportModal] = useState(false);
  const openHelpAndSupportModal = () => {
    setshowHelpAndSupportModal(true);
    setShowCard(false);
  };

  const closeHelpAndSupportModal = () => {
    setshowHelpAndSupportModal(false);
  };

  useEffect(() => {
    if (status === 200) {
      setCapturedImage(null);
    }
  }, [status]);
  return (
    <div style={{ position: "relative" }}>
      <style>{`.crosshairs {
                position: fixed;
                width:auto;
                z-index: 2147483645;
            }`}</style>{" "}
      {/* Include the CSS style */}
      <ScreenCapture onEndCapture={handleCapture}>
        {({ onStartCapture }) => (
          <>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="overlay-example">Select Area</Tooltip>}
            >
              <i
                className={`mdi mdi-select-drag ${color} ${height}`}
                // title="Select Area"
                onClick={onStartCapture}
                disabled={capturing}
              >
                {/* {capturing ? 'Capturing...' : 'Capture'} */}
              </i>
            </OverlayTrigger>
          </>
        )}
      </ScreenCapture>
      {/* {capturedImage && <Loading />} */}
      {showCard && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(100%, -50%)",
            border: "1px solid #ccc",
            padding: "10px",
            width: "300px",
            // backgroundColor: '#f8f9fa',
          }}
          className="bg-dark"
        >
          <img
            src={capturedImage}
            alt="Captured Screenshot"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <div className="d-flex justify-content-between py-0 my-0">
            <code className="mt-2 text-nowrap">{currentPageName} </code>
            <div className="d-flex justify-content-end">
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="overlay-example">Add</Tooltip>}
              >
                {/* {user === 'admin' ?
                                    <></>
                                    : <></>} */}
                <i
                  className="mdi mdi-share fs-3 text-primary pe-2 pb-0 my-0"
                  title="Share"
                  onClick={openHelpAndSupportModal}
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="overlay-example">Save</Tooltip>}
              >
                <i
                  className="mdi mdi-tray-arrow-down fs-3 text-success pe-2 pb-0 my-0"
                  title="Save"
                  onClick={handleDownload}
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="overlay-example">Cancel</Tooltip>}
              >
                <i
                  className="mdi mdi-close-circle-outline fs-3 text-danger pb-0 my-0"
                  title="Cancel"
                  onClick={handleCancel}
                  style={{ cursor: "pointer" }}
                ></i>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      )}
      <HelpSupport
        show={showHelpAndSupportModal}
        hide={closeHelpAndSupportModal}
        file={capturedImage}
      />
    </div>
  );
};

export default CroppedScreenShot;
