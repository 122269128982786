import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

export function getLocationEndPoint(params) {
  const { zip, countryCode } = params?.data;
  return api.get(
    `${URL.GET_LOCATION}?text=${zip}&lang=en&limit=10&type=postcode&filter=countrycode:${countryCode}&apiKey=0538e3bf336143dfab4cd182d7b563d1`
  );
}
