import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

function addRatingEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.ADD_RATING, data);
}
function getRatingEndPoint(params: any): any {
    const { data } = params;
    return api.get(URL.GET_RATING, data);
}

export {
    addRatingEndPoint,
    getRatingEndPoint
};
