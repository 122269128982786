import { VatDetailsActionTypes } from "./constants";

const GET_VAT_DETAILS_INITIAL_STATE = {
  getVatDetails: [],
  loading: false,
  message: ''

};
const ADD_VAT_DETAILS_INITIAL_STATE = {
  addVat: [],
  loading: false,
  message: ''
};

const initial_state = {
  data: [],
  loading: false,
  message: "",
};

const getVatDetailsReducer = (
  state = GET_VAT_DETAILS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case VatDetailsActionTypes.GET_VAT_DETAILS_LOADING:
      return {
        getVatDetails: state.getVatDetails,
        loading: true,
      };
    case VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS:
      return {
        getVatDetails: action.payload,
        loading: false,
      };
    case VatDetailsActionTypes.GET_VAT_DETAILS_ERROR:
      return {
        getVatDetails: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

const addVatDetailsReducer = (
  state = ADD_VAT_DETAILS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case VatDetailsActionTypes.ADD_VAT_DETAILS_LOADING:
      return {
        addVat: state.addVat,
        loading: true,
      };
    case VatDetailsActionTypes.ADD_VAT_DETAILS_SUCCESS:
      return {
        addVat: action.payload,
        loading: false,
      };
    case VatDetailsActionTypes.ADD_VAT_DETAILS_ERROR:
      return {
        addVat: action.payload,
        loading: false,
      };
    case VatDetailsActionTypes.ADD_VAT_DETAILS_RESET:
      return ADD_VAT_DETAILS_INITIAL_STATE;
    default:
      return state;
  }
};

const addFinalVatDetailsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_LOADING:
      return {
        initial_state: state.initial_state,
        loading: true,
      };
    case VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_ERROR:
      return {
        initial_state: action.payload,
        loading: false,
      };
    case VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_RESET:
      return initial_state;
    default:
      return state;
  }
};

export {
  getVatDetailsReducer,
  addVatDetailsReducer,
  addFinalVatDetailsReducer,
};
