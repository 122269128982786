import {
  createTicketApi,
  geTicketApi,
  getTicketByIdApi,
  updateReplyTicketApi,
} from "./api";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { ticket } from "./constant";

function* getTicketFunction({ data }) {
  try {
    yield put({
      type: ticket.GET_TICKET_LOADING,
      payload: {},
    });
    const response = yield call(geTicketApi, data);
    if (response.data.status) {
      yield put({
        type: ticket.GET_TICKET_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: ticket.GET_TICKET_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: ticket.GET_TICKET_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* getTicketByIdFunction({ data }) {
  try {
    yield put({
      type: ticket.GET_TICKET_BY_ID_LOADING,
      payload: {},
    });
    const response = yield call(getTicketByIdApi, data);
    if (response.data.status) {
      yield put({
        type: ticket.GET_TICKET_BY_ID_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: ticket.GET_TICKET_BY_ID_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: ticket.GET_TICKET_BY_ID_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* createTicketFunction({ data }) {
  try {
    yield put({
      type: ticket.CREATE_TICKET_LOADING,
      payload: {},
    });
    const response = yield call(createTicketApi, data);
    if (response.data.status) {
      yield put({
        type: ticket.CREATE_TICKET_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: ticket.CREATE_TICKET_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: ticket.CREATE_TICKET_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: ticket.CREATE_TICKET_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* updateReplyTicketFunction({ data }) {
  try {
    yield put({
      type: ticket.UPDATE_REPLY_TICKET_LOADING,
      payload: {},
    });
    const response = yield call(updateReplyTicketApi, data);
    if (response.data.status) {
      yield put({
        type: ticket.UPDATE_REPLY_TICKET_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: ticket.UPDATE_REPLY_TICKET_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: ticket.UPDATE_REPLY_TICKET_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: ticket.UPDATE_REPLY_TICKET_ERROR,
      payload: { message: error?.message },
    });
  }
}

export function* getTicketSaga() {
  yield takeEvery(ticket.GET_TICKET, getTicketFunction);
}

export function* createTicketSaga() {
  yield takeEvery(ticket.CREATE_TICKET, createTicketFunction);
}

export function* getTicketByIdSaga() {
  yield takeEvery(ticket.GET_TICKET_BY_ID, getTicketByIdFunction);
}

export function* updateReplyTicketSaga() {
  yield takeEvery(ticket.UPDATE_REPLY_TICKET, updateReplyTicketFunction);
}

function* allTicketSaga() {
  yield all([
    fork(getTicketSaga),
    fork(createTicketSaga),
    fork(getTicketByIdSaga),
    fork(updateReplyTicketSaga),
  ]);
}

export default allTicketSaga;
