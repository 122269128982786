import React, { useState, useRef, useEffect } from "react";
import ScreenshotButton from "./screenshot/ScreenShot";
import CroppedScreenShot from "./screenshot/CroppedScreenShot";
import HelpSupport from "./Modal/Help&Support";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ActionButton = () => {
  const [isCardOpen, setIsCardOpen] = useState(false);
  const cardRef = useRef(null);

  const toggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setIsCardOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showHelpAndSupportModal, setshowHelpAndSupportModal] = useState(false);
  const openHelpAndSupportModal = () => {
    setshowHelpAndSupportModal(true);
  };

  const closeHelpAndSupportModal = () => {
    setshowHelpAndSupportModal(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "9999",
      }}
    >
      <button
        style={{
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          fontSize: "24px",
          cursor: "pointer",
          outline: "none",
          textAlign: "center",
          position: "relative",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
        }}
        onClick={toggleCard}
      >
        <i className="mdi mdi-robot-vacuum fs-2"></i>
      </button>
      {isCardOpen && (
        <div
          ref={cardRef}
          style={{
            position: "absolute",
            top: "-135px",
            right: "0",
            width: "50px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: "9999",
            padding: "10px",
          }}
          className="text-center bg-secondary"
        >
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="overlay-example">Help & Support</Tooltip>}
          >
            <i
              className="mdi mdi-help-circle-outline fs-3 text-light"
              onClick={openHelpAndSupportModal}
            ></i>
          </OverlayTrigger>
          <CroppedScreenShot color={"text-light"} height={"fs-3"} />
          <ScreenshotButton color={"text-light"} height={"fs-3"} />
        </div>
      )}
      <HelpSupport
        show={showHelpAndSupportModal}
        hide={closeHelpAndSupportModal}
      />
    </div>
  );
};

export default ActionButton;
