import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AuthComponentDataSend } from './constants';
import { StateEmptyActionTypes } from '../../stateEmpty/constants';


function* otpTimeStartFunction() {
    yield put({
        type: AuthComponentDataSend.OTP_TIME_START_SUCCESS,
    });
}

function* signupVerifyBackBtnFunction() {
    yield put({
        type: AuthComponentDataSend.SIGNUP_VERIFY_BACK_BTN_SUCCESS,
    });
}

function* stateEmptyFunction() {
    yield put({
        type: StateEmptyActionTypes.STATE_EMPTY_SUCCESS,
    });
}

function* MbleScrnSideBrClseFunction() {
    yield put({
        type: AuthComponentDataSend.MOBILE_SCREEN_SIDE_BAR_CLOSE_SUCCESS,
    });
}

export function* acctionOtpTimeStart(): any {
    yield takeEvery(AuthComponentDataSend.OTP_TIME_START_FIRST, otpTimeStartFunction);
}

export function* acctionSignupVerifyBackBtn(): any {
    yield takeEvery(AuthComponentDataSend.SIGNUP_VERIFY_BACK_BTN_FIRST, signupVerifyBackBtnFunction);
}

export function* acctionStateEmtpy(): any {
    yield takeEvery(StateEmptyActionTypes.STATE_EMPTY_FIRST, stateEmptyFunction);
}

export function* acctionMbleScrnSideBrClse(): any {
    yield takeEvery(AuthComponentDataSend.MOBILE_SCREEN_SIDE_BAR_CLOSE_FIRST, MbleScrnSideBrClseFunction);
}
function* authComponentDataSendSaga(): any {
    yield all([
        fork(acctionOtpTimeStart),
        fork(acctionSignupVerifyBackBtn),
        fork(acctionStateEmtpy),
        fork(acctionMbleScrnSideBrClse)
       
    ]);
}

export default authComponentDataSendSaga;
