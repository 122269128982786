export const announcements = {
  //   GET
  GET_ANNOUNCEMENT: "GET_ANNOUNCEMENT",
  GET_ANNOUNCEMENT_LOADING: "GET_ANNOUNCEMENT_LOADING",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS",
  GET_ANNOUNCEMENT_ERROR: "GET_ANNOUNCEMENT_ERROR",
  GET_ANNOUNCEMENT_RESET: "GET_ANNOUNCEMENT_RESET",
  //   POST
  POST_ANNOUNCEMENT: "POST_ANNOUNCEMENT",
  POST_ANNOUNCEMENT_LOADING: "POST_ANNOUNCEMENT_LOADING",
  POST_ANNOUNCEMENT_SUCCESS: "POST_ANNOUNCEMENT_SUCCESS",
  POST_ANNOUNCEMENT_ERROR: "POST_ANNOUNCEMENT_ERROR",
  POST_ANNOUNCEMENT_RESET: "POST_ANNOUNCEMENT_RESET",
  //   UPDATE
  UPDATE_ANNOUNCEMENT: "UPDATE_ANNOUNCEMENT",
  UPDATE_ANNOUNCEMENT_LOADING: "UPDATE_ANNOUNCEMENT_LOADING",
  UPDATE_ANNOUNCEMENT_SUCCESS: "UPDATE_ANNOUNCEMENT_SUCCESS",
  UPDATE_ANNOUNCEMENT_ERROR: "UPDATE_ANNOUNCEMENT_ERROR",
  UPDATE_ANNOUNCEMENT_RESET: "UPDATE_ANNOUNCEMENT_RESET",
  //   DELETE
  DELETE_ANNOUNCEMENT: "DELETE_ANNOUNCEMENT",
  DELETE_ANNOUNCEMENT_LOADING: "DELETE_ANNOUNCEMENT_LOADING",
  DELETE_ANNOUNCEMENT_SUCCESS: "DELETE_ANNOUNCEMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_ERROR: "DELETE_ANNOUNCEMENT_ERROR",
  DELETE_ANNOUNCEMENT_RESET: "DELETE_ANNOUNCEMENT_RESET",

  GET_ANNOUNCEMENT_ROLE: "GET_ANNOUNCEMENT_ROLE",
  GET_ANNOUNCEMENT_LOADING_ROLE: "GET_ANNOUNCEMENT_LOADING_ROLE",
  GET_ANNOUNCEMENT_SUCCESS_ROLE: "GET_ANNOUNCEMENT_SUCCESS_ROLE",
  GET_ANNOUNCEMENT_ERROR_ROLE: "GET_ANNOUNCEMENT_ERROR_ROLE",
  GET_ANNOUNCEMENT_RESET_ROLE: "GET_ANNOUNCEMENT_RESET_ROLE",

  SEARCH_CLIENT: "SEARCH_CLIENT",
  SEARCH_CLIENT_LOADING: "SEARCH_CLIENT_LOADING",
  SEARCH_CLIENT_SUCCESS: "SEARCH_CLIENT_SUCCESS",
  SEARCH_CLIENT_ERROR: "SEARCH_CLIENT_ERROR",
  SEARCH_CLIENT_RESET: "SEARCH_CLIENT_RESET",
};
