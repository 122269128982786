const AccountType = {
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
  UPDATE_PROFILE_RESET: "UPDATE_PROFILE_RESET",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_LOADING: "UPDATE_PASSWORD_LOADING",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",
  UPDATE_PASSWORD_RESET: "UPDATE_PASSWORD_RESET",

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_LOADING: "GET_PROFILE_LOADING",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",
  GET_PROFILE_RESET: "GET_PROFILE_RESET",

  MOBILE_NUMBER_VERIFY_FIRST: "MOBILE_NUMBER_VERIFY_FIRST",
  MOBILE_NUMBER_VERIFY_LOADING: "MOBILE_NUMBER_VERIFY_LOADING",
  MOBILE_NUMBER_VERIFY_SUCCESS: "MOBILE_NUMBER_VERIFY_SUCCESS",
  MOBILE_NUMBER_VERIFY_ERROR: "MOBILE_NUMBER_VERIFY_ERROR",
  MOBILE_NUMBER_VERIFY_RESET: "MOBILE_NUMBER_VERIFY_RESET",

  MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST: "MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST",
  MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING:
    "MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING",
  MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS:
    "MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS",
  MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR: "MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR",
  MOBILE_NUMBER_VERIFY_OTP_SEND_RESET: "MOBILE_NUMBER_VERIFY_OTP_SEND_RESET",
  MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST: "MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST",
};

export default AccountType;
