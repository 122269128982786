import { AccountantSearchActionTypes } from './constants';

const GET_SEARCH_ACCOUNTANT_INITIAL_STATE = {
    getSearchAccountant: [],
    loading: false,
};
const initial_state = {
    data: [],
    loading: false,
    message: ''
};


const getSearchAccountantReducer = (
    state = GET_SEARCH_ACCOUNTANT_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_LOADING:
            return {
                getSearchAccountant: state.getSearchAccountant,
                loading: true,
            };
        case AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_SUCCESS:
            return {
                getSearchAccountant: action.payload,
                loading: false,
            };
        case AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_ERROR:
            return {
                getSearchAccountant: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const ignoreClienrRequestReducer = (state = initial_state, action) => {
    switch (action.type) {
        case AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_SUCCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_RESET:
            return {
                data: state,
                loading: false,
            };
        case AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

const getMeetingStatusDetailsReducer = (
    state = initial_state,
    action
) => {
    switch (action.type) {
        case AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export {
    getSearchAccountantReducer,
    ignoreClienrRequestReducer,
    getMeetingStatusDetailsReducer
}
