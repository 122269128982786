export const OtpSendEmailActionTypes = {
    OTP_SEND_EMAIL_FIRST: '@@auth/OTP_SEND_EMAIL_FIRST',
    OTP_SEND_EMAIL_LOADING: '@@auth/OTP_SEND_EMAIL_LOADING',
    OTP_SEND_EMAIL_SUCCESS: '@@auth/OTP_SEND_EMAIL_SUCCESS',
    OTP_SEND_EMAIL_ERROR: '@@auth/OTP_SEND_EMAIL_ERROR',
    OTP_SEND_EMAIL_RESET: '@@auth/OTP_SEND_EMAIL_RESET',

    SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_FIRST: '@@auth/SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_FIRST',
    SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_LOADING: '@@auth/SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_LOADING',
    SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_SUCCESS: '@@auth/SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_SUCCESS',
    SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_ERROR: '@@auth/SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_ERROR',
    SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_RESET: '@@auth/SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_RESET',
    
};
