import { APICore } from '../../../helpers/api/apiCore';
import * as URL from "../../../helpers/api/apiEndPoint";
const api = new APICore();

function getAccountantClient(params): any {
    const { name, limit, skip } = params || {};
    return api.get(`${URL.GET_ACCOUNTANT_CLIENT}?limit=${limit}&page=${skip}&name=${name ? name : ""}`);
}

function getClientInvitationRequestApi(params): any {
    return api.get(URL.GET_CLIENT_INVITATION_REQUEST);
}

function acceptClientInvitationRequestApi(data) {
    return api.update(URL.ACCEPT_CLIENT_INVITATION, data?.payload);
}

function getDocusignTokenEndPoint(data: any) {
    // return api.create(URL.GET_CALENDLY_APITOKEN);
    return api.get(URL.GET_DOCUSIGN_APITOKEN);
}

function getContractListEndPoint(params) {
    // console.log(params, "params");

    const { clientId, accessToken } = params || {};

    // console.log(accessToken, "clientId");
    return api.get(`${URL.GET_CONTRACT}clientId=${clientId}&accessToken=${accessToken}`);
}


function getClientContractListEndPoint(data: any) {
    return api.create(URL.GET_CLIENT_CONTRACT, data?.payload);
}

export {
    getAccountantClient,
    getClientInvitationRequestApi,
    acceptClientInvitationRequestApi,
    getDocusignTokenEndPoint,
    getContractListEndPoint,
    getClientContractListEndPoint
};
