export const USER_LOGIN = "/api/user/userLogin";
export const BUSINESS_EMAIL_OTP = "/api/user/emailVerify";
export const VERIFY_EMAIL_OTP = "/api/user/verifyOtp";
export const SUB_ACCOUNTANT_EMAIL_SEND_OTP = "/api/user/sendOtp?";
export const GET_COMPANIES_BY_SEARCH = "/api/user/getCompaniesBySearch?";
export const REGISTER_USER = "/api/user/registerUser";
export const COMPANY_CHECK_VERIFY = "/api/user/organizationCheck";
export const COMPANY_CREATE = "/api/onboard/companyRequest";
export const RESET_PASSWORD_EMAIL = "/api/user/forgotPassword";
export const RESET_PASSWORD_VERITY = "/api/user/changePassword";
export const UPDATE_PROFILE = "/api/user/clientUpdate";
// export const Get_PROFILE = "/api/user/getClientProfilelll";
export const GET_ORGNISATION = "/api/organization/getUserOrganization?";
export const UPDATE_ORGNISATION = "/api/organization/updateUserOrganization";
export const UPDATE_PASSWORD = "/api/user/changePassword";
export const GET_SUBUSER = "/api/user/getClientsubUser";
export const ADD_SUBUSER = "/api/user/registerUser";
export const UPDATE_SUBUSER = "/api/user/clientUpdate";
export const EMAIL_VERIFY_ON_ADD_DETAILS = "/api/user/accouIntantEmailVerify";

// NEW CODE
export const GET_SKILL = "/api/user/getSkills?";
export const SIGNUP_ACCOUNT = "/api/user/registerUser";
export const ACCOUNT_DETAIL_ACCOUNT = "/api/user/accountDetails";
export const ADD_SUBACCOUNTANT = "/api/user/registerUser";
export const GET_SUBACCOUNTANT = "/api/user/getAccountantSubUsers";
export const UPDATE_SUBACCOUNTANT = "/api/user/accountantUpdate";
export const DELETE_SUBACCOUNTANT = "/api/user/subUserDelete?";
export const GET_ACCOUNTANT_PROFILE = "/api/user/getAccountantProfile";
export const ACCOUNT_SETTING_ACCOUNT = "/api/user/accountantUpdate";
export const EMAIL_VERIFY_SEND = "/api/organization/organizationEmailVerify";
export const EMAIL_VERIFY_DELETE = "/api/organization/deleteMail?";
export const OTP_VERIFY_SEND = "/api/organization/otpVerify";
export const GET_SEARCH_ACCOUNTANT_LIST = "/api/onboard/accountantSearch?";
export const MOBILE_NUMBER_VERIFY = "/api/user/phoneVerify";
export const MOBILE_NUMBER_VERIFY_OTP_SEND = "/api/user/verifyOtp";
export const GET_ANNOUNCEMENT = "/api/announcement/getAnnouncement";
export const POST_ANNOUNCEMENT = "/api/announcement/addAnnouncement";
export const UPDATE_ANNOUNCEMENT = "/api/announcement/updateAnnouncement";
export const DELETE_ANNOUNCEMENT = "/api/announcement/deleteAnnouncement";
export const GET_ACCOUNTANT_CLIENT = "/api/accountant/getAllAssignClients";
export const GET_VAT_DETAILS = "/api/accountant/getVatDetails?";
export const ADD_VAT_DETAILS = "/api/onboard/addVatDetails";
export const ADD_FINAL_VAT_DETAILS = "/api/onboard/finalVatSubmission";
export const GET_ANNOUNCEMENT_ACCOUNTANT =
  "/api/announcement/accountantAnnouncement";
export const SEARCH_CLIENT = "/api/onboard/clientSearch";
export const GET_ITAA = "/api/accountant/getItaaDetail?";
export const GET_INDUSTORY = "/api/accountant/getIndustry?";
export const GET_CLIENT_INVITATION_REQUEST =
  "/api/accountant/getClientInvitationRequest";
export const ACCEPT_CLIENT_INVITATION =
  "api/accountant/acceptClientInvitationRequest";
export const GET_DOCUSIGN_APITOKEN = "/getAccessToken";
// Get Contract
export const GET_CONTRACT = "/api/accountant/getaccountantContractPageData?";
// Get Client Contract
export const GET_CLIENT_CONTRACT = "/api/accountant/getAccountantContract";
export const GET_LANGUAGES = "api/master/getLanguage";
export const ADD_RATING = "api/rating/createClientRating";
export const GET_RATING = "api/rating/getAccountantRating";
export const IGNORE_CLIENT_REQUEST =
  "api/accountant/ignoreClientInvitationRequest";
export const GET_MEETING_DETAILS_STATUS = "api/accountant/getMeetingDetail";
export const GET_NOTIFECTION = "api/notification/accountantNotification";
export const GET_QOUNT_NOTIFECTION = "api/notification/newNotifications";
export const GET_LOCATION = "https://api.geoapify.com/v1/geocode/search";
export const GET_TICKET = "/api/support/getTicket?status=";
export const GET_TICKET_BY_ID = "/api/support/getTicketById";
export const CREATE_TICKET = "/api/support/createTicket";
export const UPDATE_TICKET = "/api/support/updateStatus";
