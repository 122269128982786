import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { VatDetailsActionTypes } from "./constants";
import { getVatDetails, addVatDetails, addFinalVatDetails } from "./api";

function* callGetVatDetails(data) {
  try {
    yield put({
      type: VatDetailsActionTypes.GET_VAT_DETAILS_LOADING,
      payload: {},
    });
    const response = yield call(getVatDetails, data);
    // console.log(data, "jjjjjjjj");
    if (response.data.status) {
      yield put({
        type: VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
      payload: error,
    });
  }
}

function* callAddVatDetails(data) {
  try {
    yield put({
      type: VatDetailsActionTypes.ADD_VAT_DETAILS_LOADING,
      payload: {},
    });
    const response = yield call(addVatDetails, data);
    if (response.data.status) {
      yield put({
        type: VatDetailsActionTypes.ADD_VAT_DETAILS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: VatDetailsActionTypes.ADD_VAT_DETAILS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: VatDetailsActionTypes.ADD_VAT_DETAILS_ERROR,
        payload: { ...response.data },
      });
      yield put({
        type: VatDetailsActionTypes.ADD_VAT_DETAILS_RESET,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: VatDetailsActionTypes.ADD_VAT_DETAILS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: VatDetailsActionTypes.ADD_VAT_DETAILS_RESET,
      payload: {},
    });
  }
}

function* callAddFinalVatDetails(data) {
  try {
    yield put({
      type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_LOADING,
      payload: {},
    });
    const response = yield call(addFinalVatDetails, data);
    if (response.data.status) {
      yield put({
        type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_ERROR,
        payload: { ...response.data },
      });
      yield put({
        type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_RESET,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_ERROR,
      payload: error,
    });
    yield put({
      type: VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_RESET,
      payload: {},
    });
  }
}

export function* getVatDetailsAction(): any {
  yield takeEvery(
    VatDetailsActionTypes.GET_VAT_DETAILS_FIRST,
    callGetVatDetails
  );
}
export function* addVatDetailsAction(): any {
  yield takeEvery(
    VatDetailsActionTypes.ADD_VAT_DETAILS_FIRST,
    callAddVatDetails
  );
}
export function* addFinalVatDetailsAction(): any {
  yield takeEvery(
    VatDetailsActionTypes.ADD_FINAL_VAT_DETAILS_FIRST,
    callAddFinalVatDetails
  );
}

function* getVatDetailsSaga(): any {
  yield all([
    fork(getVatDetailsAction),
    fork(addVatDetailsAction),
    fork(addFinalVatDetailsAction),
  ]);
}
export default getVatDetailsSaga;
