import { ticket } from "./constant";

export const getTicket = (data) => ({
  type: ticket?.GET_TICKET,
  data,
});

export const getTicketById = (data) => ({
  type: ticket?.GET_TICKET_BY_ID,
  data,
});

export const createTicket = (data) => ({
  type: ticket?.CREATE_TICKET,
  data,
});

export const updateReplyTicket = (data) => ({
  type: ticket?.UPDATE_REPLY_TICKET,
  data,
});
