import { subAccountant, subUser } from "./constants";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};
const ADD_SUBACCOUNTANT_INITIAL_STATE = {
  subAccountant: [],
  loading: false,
};

const UPDATE_SUBACCOUNTANT_INITIAL_STATE = {
  subAccountantUpdate: [],
  loading: false,
};

// new code
const DELETE_SUB_CLIENT_INITIAL_STATE = {
  deleteSubClient: [],
  loading: false,
};
export const getSubAccountantReducer = (state = initial_state, action) => {
  switch (action.type) {
    case subAccountant.GET_SUBACCOUNTANT_LOADING:
      return {
        data: initial_state.data,
        loading: true,
      };
    case subAccountant.GET_SUBACCOUNTANT_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case subAccountant.GET_SUBACCOUNTANT_ERROR:
      return {
        data: [],
        loading: false,
        message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const addSubAccountantReducer = (
  state = ADD_SUBACCOUNTANT_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case subAccountant.ADD_SUBACCOUNTANT_LOADING:
      return {
        subAccountant: state?.subAccountant,
        loading: true,
      };
    case subAccountant.ADD_SUBACCOUNTANT_SUCCESS:
      return {
        subAccountant: action?.payload,
        loading: false,
      };
    case subAccountant.ADD_SUBACCOUNTANT_RESET:
      return {
        subAccountant: initial_state.data,
        loading: false,
      };
    case subAccountant.ADD_SUBACCOUNTANT_ERROR:
      return {
        subAccountant: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const updateSubAccountantReducer = (
  state = UPDATE_SUBACCOUNTANT_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case subAccountant.UPDATE_SUBACCOUNTANT_LOADING:
      return {
        subAccountantUpdate: state.subAccountantUpdate,
        loading: true,
      };
    case subAccountant.UPDATE_SUBACCOUNTANT_SUCCESS:
      return {
        subAccountantUpdate: action?.payload,
        loading: false,
      };
    case subAccountant.UPDATE_SUBACCOUNTANT_RESET:
      return {
        subAccountantUpdate: state,
        loading: false,
      };
    case subAccountant.UPDATE_SUBACCOUNTANT_ERROR:
      return {
        subAccountantUpdate: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

// export const subAccountant_delete_reducer = (state = initial_state, action) => {
//   switch (action.type) {
//     case subAccountant.DELETE_SUBACCOUNTANT_LOADING:
//       return { data: [], loading: true };
//     case subAccountant.DELETE_SUBACCOUNTANT_SUCCESS:
//       return { data: action?.payload, loading: false };
//     case subAccountant.DELETE_SUBACCOUNTANT_RESET:
//       return initial_state;
//     case subAccountant.DELETE_SUBACCOUNTANT_ERROR:
//       return { ...state, message: action.payload, loading: false };
//     default:
//       return { ...state };
//   }
// };

export const deleteSubClientReducer = (
  state = DELETE_SUB_CLIENT_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case subAccountant.DELETE_SUBACCOUNTANT_LOADING:
      return {
        deleteSubClient: state.deleteSubClient,
        loading: true,
      };
    case subAccountant.DELETE_SUBACCOUNTANT_SUCCESS:
      return {
        deleteSubClient: action?.payload,
        loading: false,
      };
    case subAccountant.DELETE_SUBACCOUNTANT_RESET:
      return {
        deleteSubClient: state,
        loading: false,
      };
    case subAccountant.DELETE_SUBACCOUNTANT_ERROR:
      return {
        deleteSubClient: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};
