import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { BankAccountDetailActionTypes } from "./constants";
import {
  accountDetailEndPoint,
  accountSettingEndPoint,
  getAccountantProfileApi,
  mobileNumberVerifyUpdateEndPoint,
  mobileNumberVerifyOtpSendEndPoint,
} from "./api";

function* accountDetailFunction(data) {
  try {
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_LOADING,
      payload: {},
    });
    const response = yield call(accountDetailEndPoint, data);
    if (response.data.status) {
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_ERROR,
      payload: error?.data,
    });
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_DETAIL_RESET,
      payload: {},
    });
  }
}

function* accountSettingFunction(data) {
  try {
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_SETTING_LOADING,
      payload: {},
    });
    const response = yield call(accountSettingEndPoint, data);
    if (response.data.status) {
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_SETTING_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_SETTING_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: BankAccountDetailActionTypes.ACCOUNT_SETTING_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_SETTING_ERROR,
      payload: error?.data,
    });
    yield put({
      type: BankAccountDetailActionTypes.ACCOUNT_SETTING_RESET,
      payload: {},
    });
  }
}

function* getProfileAccountantFunction() {
  try {
    yield put({
      type: BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(getAccountantProfileApi);
    if (response.data.status) {
      yield put({
        type: BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_RESET,
      payload: {},
    });
  }
}

function* mobileNumberVerifyFunction(data) {
  try {
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_LOADING,
      payload: {},
    });
    const response = yield call(mobileNumberVerifyUpdateEndPoint, data);
    if (response.data.status) {
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_ERROR,
      payload: error?.data,
    });
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_RESET,
      payload: {},
    });
  }
}

function* mobileNumberVerifyOTPsendFunction(data) {
  try {
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING,
      payload: {},
    });
    const response = yield call(mobileNumberVerifyOtpSendEndPoint, data);
    if (response.data.status) {
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR,
      payload: error?.data,
    });
    yield put({
      type: BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET,
      payload: {},
    });
  }
}

export function* acctionAccountDetail(): any {
  yield takeEvery(
    BankAccountDetailActionTypes.ACCOUNT_DETAIL_FIRST,
    accountDetailFunction
  );
}

export function* acctionSettingDetail(): any {
  yield takeEvery(
    BankAccountDetailActionTypes.ACCOUNT_SETTING_FIRST,
    accountSettingFunction
  );
}

export function* acctionaGetAccountantProfile(): any {
  yield takeEvery(
    BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_FIRST,
    getProfileAccountantFunction
  );
}

export function* acctioMobileNumberVerify(): any {
  yield takeEvery(
    BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_FIRST,
    mobileNumberVerifyFunction
  );
}

export function* acctioMobileNumberVerifyOTPsend(): any {
  yield takeEvery(
    BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST,
    mobileNumberVerifyOTPsendFunction
  );
}

function* accountDetailSaga(): any {
  yield all([
    fork(acctionAccountDetail),
    fork(acctionSettingDetail),
    fork(acctionaGetAccountantProfile),
    fork(acctioMobileNumberVerify),
    fork(acctioMobileNumberVerifyOTPsend),
  ]);
}

export default accountDetailSaga;
