import { OtpSendEmailActionTypes } from './constants';

const VERIFY_OTP_EMAIL_INITIAL_STATE = {
    verifyOtpEmail: [],
    loading: false,
};


const SUB_ACCOUNTANT_VERIFY__EMAIL_INITIAL_STATE = {
    subAccountantVerifyEmailSend: [],
    loading: false,
};


const otpSendEmailReducer = (
    state = VERIFY_OTP_EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_LOADING:
            return {
                verifyOtpEmail: state.verifyOtpEmail,
                loading: true,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_SUCCESS:
            return {
                verifyOtpEmail: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_ERROR:
            return {
                verifyOtpEmail: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.OTP_SEND_EMAIL_RESET:
            return VERIFY_OTP_EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};

const subAccountantSendEmailVerifyReducer = (
    state = SUB_ACCOUNTANT_VERIFY__EMAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OtpSendEmailActionTypes.SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_LOADING:
            return {
                subAccountantVerifyEmailSend: state.subAccountantVerifyEmailSend,
                loading: true,
            };
        case OtpSendEmailActionTypes.SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_SUCCESS:
            return {
                subAccountantVerifyEmailSend: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_ERROR:
            return {
                subAccountantVerifyEmailSend: action.payload,
                loading: false,
            };
        case OtpSendEmailActionTypes.SUB_ACCOUNTANT_EMAIL_SEND_VERIFY_RESET:
            return SUB_ACCOUNTANT_VERIFY__EMAIL_INITIAL_STATE;
        default:
            return state;
    }
};


export {
    otpSendEmailReducer,
    subAccountantSendEmailVerifyReducer
}
