import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();


function getNotifectionEndPoint(params: any): any {
    const { data } = params;
    return api.get(URL.GET_NOTIFECTION, data);
}

function getQountNotifectionEndPoint(params: any): any {
    const { data } = params;
    return api.get(URL.GET_QOUNT_NOTIFECTION, data);
}

export {
    getNotifectionEndPoint,
    getQountNotifectionEndPoint
};
