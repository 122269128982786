import { create, event, select } from "d3-selection"
import { extent } from "d3-array"
import { scaleBand, scaleLinear } from "d3-scale"
import { axisBottom, axisLeft } from "d3-axis"
import { Renderer, Rendition } from "../renderer"
import { Widget } from "../widget"
import { tooltip } from "../../util/tooltip"
import { NumberFormat } from "../i18n"

interface Bar {
  year: string
  value0: number
  note: string
  formattedValue: string
  source?: {
    publicationTitle: string
  }
}

interface ChartData {
  colors: string[]
  title: string
  colorIndexes: number[]
  estimate: boolean[]
  numberFormat: NumberFormat
  data: Bar[]
}

interface Chart {
  data: ChartData
  title: string
  item: string
}

interface Data {
  note: string
  item: Chart[]
}

export const barChart: Renderer = function (
  widget: Widget,
  allData: Data
): Rendition {
  type Drawable = () => void
  const drawables: Drawable[] = []
  const note = allData.note

  if ((!allData.item || allData.item.length == 0) && !note) {
    throw new Error("no data")
  }

  const margin = { top: 15, right: 10, bottom: 50, left: 15 }

  const hoverTooltip = tooltip()

  function tooltipContent(item: Bar, title: string) {
    const itemNote = item.note
      ? "<span class='note'>" + item.note + "</span>"
      : ""
    const itemSource =
      item.source && item.source.publicationTitle
        ? "<span class='source'>" +
          widget.trilingual("Source: ", "Quelle: ", "Source: ") +
          item.source.publicationTitle +
          "</span>"
        : ""
    return (
      "<span class='name'>" +
      title +
      " " +
      item.year +
      "</span>" +
      "<span class='value'>" +
      item.formattedValue +
      "</span>" +
      itemNote +
      itemSource
    )
  }

  for (const detail of allData.item) {
    const data = detail.data
    if (!data) {
      continue
    }

    const colorValue = widget.options.colors
    let colors: string[] =
      typeof colorValue == "string" ? colorValue.split("|") : colorValue
    colors = colors ?? data.colors

    const key = data.title.substring(0, 3)
    const barColors: string[] = []
    const colorIndexes = data.colorIndexes
    for (let i = 0; i < colorIndexes.length; ++i) {
      const colorIndex = colorIndexes[i]
      const barColor = data.estimate[i]
        ? "url(#diag" + key + colorIndex + ")"
        : colors[colorIndex]
      barColors.push(barColor)
    }

    const patternDefs = create("svg:defs")
    patternDefs
      .selectAll("pattern")
      .data(colors)
      .join("svg:pattern")
      .attr("id", (_, index) => `diag${key}${index}`)
      .attr("width", 10)
      .attr("height", 10)
      .attr("patternTransform", "rotate(45 0 0)")
      .attr("patternUnits", "userSpaceOnUse")
      .attr(
        "transform",
        "matrix(0.707107, 0.707107, -0.707107, 0.707107, 0, 0)"
      )
      .append("svg:line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 10)
      .style("stroke", (color) => color)
      .style("stroke-width", 15)

    let [min, max] = extent(data.data, (item: Bar) => item.value0) as [
      number,
      number
    ]
    max = Math.max(max, 1)
    min = Math.min(min, 0)
    const yTicks = Math.min(5, Math.ceil(max))

    const itemNode = document.createElement("div")
    itemNode.setAttribute("data-item", detail.item)
    widget.showItem(detail.title, itemNode)

    const svg = select(itemNode)
      .style("height", "100%")
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")

    svg.attr("aria-label", detail.title)

    svg.append(() => patternDefs.node())

    const xScale = scaleBand()
      .domain(data.data.map((bar) => bar.year))
      .padding(0.2)
    const xAxis = axisBottom(xScale)
    const xAxisGroup = svg.append("g").classed("x-axis", true)

    const yScale = scaleLinear().domain([min, max])
    const yAxis = axisLeft<number>(yScale)
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .ticks(yTicks)
      .tickFormat(
        widget.i18n().createNumberFormatter(data.numberFormat, ",.0f")
      )
    const yAxisGroup = svg.append("g").classed("y-axis", true)

    const tooltipGroup = svg.append("g")
    const rectGroup = svg.append("g")

    const redraw = () => {
      const { width, height } = itemNode.getBoundingClientRect()

      svg.attr("viewBox", `0 0 ${width} ${height}`)

      yScale.range([height - margin.bottom, margin.top])
      yAxisGroup
        .call(yAxis)
        .attr("font-size", null)
        .attr("font-family", null)
        .call((g) =>
          g
            .selectAll<SVGGElement, number>(".tick")
            .classed("zero", (d) => d === 0)
        )

      // Determine size of the widest label
      let yAxisLabelWidth = 45
      yAxisGroup
        .selectAll<SVGSVGElement, unknown>(".tick > text")
        .each(function (this: SVGSVGElement) {
          yAxisLabelWidth = Math.max(yAxisLabelWidth, this.getBBox().width)
        })

      // Adjust inner tick size by setting x2 attribute
      // `tickSizeInner` cannot be used as the left margin is not known before rendering
      const marginLeft = margin.left + yAxisLabelWidth
      yAxisGroup
        .attr("transform", `translate(${marginLeft},0)`)
        .selectAll(".tick > line")
        .attr("x2", width - marginLeft - margin.right)

      xScale.range([marginLeft, width - margin.right])
      xAxis.tickSizeInner(-(height - margin.top - margin.bottom))
      xAxisGroup
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(xAxis)
        .call((g) => g.select(".domain").remove())
        .attr("font-size", null)
        .attr("font-family", null)

      const barHeight = (value0: number) =>
        Math.abs((yScale(0) as number) - (yScale(value0) as number))

      rectGroup
        .selectAll("rect")
        .data(data.data)
        .join("rect")
        .attr("x", (d) => xScale(d.year) as number)
        .attr("y", (d) => yScale(Math.max(d.value0, 0)) as number)
        .attr("height", (d) =>
          // Ensure that the height is always >= 0
          // Otherwise the bar isn't displayed and doesn't show tooltips, etc.
          Math.max(barHeight(d.value0), 1.0)
        )
        .attr("width", xScale.bandwidth())
        .attr("fill", (_, index) => barColors[index])

      // Create a transparent bar for tooltips
      // if the original bar height is below the threshold
      const minimumBarHeight = 45
      tooltipGroup
        .selectAll("rect")
        .data(data.data.filter((d) => barHeight(d.value0) < minimumBarHeight))
        .join("rect")
        .attr("x", (d) => xScale(d.year) as number)
        .attr("y", (d) => {
          const offset = d.value0 > 0 ? minimumBarHeight : 0
          return (yScale(0) as number) - offset
        })
        .attr("height", minimumBarHeight)
        .attr("width", xScale.bandwidth())
        .attr("fill", "transparent")

      // Attach tooltip event to all rects
      svg
        .selectAll<SVGElement, Bar>("g > rect")
        .on("mousemove", () => {
          const mouseEvent = event as MouseEvent
          hoverTooltip.position(mouseEvent.clientX, mouseEvent.clientY)
        })
        .on("mouseover", (bar) => {
          hoverTooltip.content(tooltipContent(bar, detail.title)).visible(true)
        })
        .on("mouseout", () => {
          hoverTooltip.visible(false)
        })
    }

    drawables.push(redraw)
  }

  if (note) {
    const noteNode = document.createElement("div")
    const html = select(noteNode).classed("note", true).html(note)
    widget.markup(html)
    widget.container.appendChild(noteNode)
  }

  return {
    draw: function () {
      for (const drawable of drawables) {
        drawable()
      }
    },
  }
}
