export const BankAccountDetailActionTypes = {
    ACCOUNT_DETAIL_FIRST: '@@auth/ACCOUNT_DETAIL_FIRST',
    ACCOUNT_DETAIL_LOADING: '@@auth/ACCOUNT_DETAIL_LOADING',
    ACCOUNT_DETAIL_SUCCESS: '@@auth/ACCOUNT_DETAIL_SUCCESS',
    ACCOUNT_DETAIL_ERROR: '@@auth/ACCOUNT_DETAIL_ERROR',
    ACCOUNT_DETAIL_RESET: '@@auth/ACCOUNT_DETAIL_RESET',

    ACCOUNT_SETTING_FIRST: '@@auth/ACCOUNT_SETTING_FIRST',
    ACCOUNT_SETTING_LOADING: '@@auth/ACCOUNT_SETTING_LOADING',
    ACCOUNT_SETTING_SUCCESS: '@@auth/ACCOUNT_SETTING_SUCCESS',
    ACCOUNT_SETTING_ERROR: '@@auth/ACCOUNT_SETTING_ERROR',
    ACCOUNT_SETTING_RESET: '@@auth/ACCOUNT_SETTING_RESET',

    GET_PROFILE_ACCOUNTANT_FIRST: "GET_PROFILE_ACCOUNTANT_FIRST",
    GET_PROFILE_ACCOUNTANT_LOADING: "GET_PROFILE_ACCOUNTANT_LOADING",
    GET_PROFILE_ACCOUNTANT_SUCCESS: "GET_PROFILE_ACCOUNTANT_SUCCESS",
    GET_PROFILE_ACCOUNTANT_ERROR: "GET_PROFILE_ACCOUNTANT_ERROR",
    GET_PROFILE_ACCOUNTANT_RESET: "GET_PROFILE_ACCOUNTANT_RESET",

    MOBILE_NUMBER_VERIFY_FIRST: "MOBILE_NUMBER_VERIFY_FIRST",
    MOBILE_NUMBER_VERIFY_LOADING: "MOBILE_NUMBER_VERIFY_LOADING",
    MOBILE_NUMBER_VERIFY_SUCCESS: "MOBILE_NUMBER_VERIFY_SUCCESS",
    MOBILE_NUMBER_VERIFY_ERROR: "MOBILE_NUMBER_VERIFY_ERROR",
    MOBILE_NUMBER_VERIFY_RESET: "MOBILE_NUMBER_VERIFY_RESET",

    MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST: "MOBILE_NUMBER_VERIFY_OTP_SEND_FIRST",
    MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING: "MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING",
    MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS: "MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS",
    MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR: "MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR",
    MOBILE_NUMBER_VERIFY_OTP_SEND_RESET: "MOBILE_NUMBER_VERIFY_OTP_SEND_RESET",
};
