import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function uploadProfileApi(data): any {
  return api.update(URL.UPDATE_SUBACCOUNTANT, data.payload);
}

export function uploadPasswordApi(data): any {
  return api.update(URL.UPDATE_PASSWORD, data.payload);
}
export function getProfileApi(data): any {
  return api.get("", data.payload);
}

export function mobileNumberVerifyUpdateEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.MOBILE_NUMBER_VERIFY, data);
}
