import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { BusinessEmailActionTypes } from './constants';
import { businessEmailEndPoint, businessEmailVerifyEndPoint } from './api';


function* businessEmailFunction(data) {
    try {
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_LOADING,
            payload: {},
        });
        const response = yield call(businessEmailEndPoint, data);
        // console.log(response,'emailresp')
        if (response.data.status) {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_ERROR,
            payload: error?.data,
        });
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_RESET,
            payload: {},
        });
    }
}

function* businessEmailVerifyFunction(data) {
    try {
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_LOADING,
            payload: {},
        });
        const response = yield call(businessEmailVerifyEndPoint, data);
        // console.log(response,'emailresp')
        if (response.data.status) {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_ERROR,
            payload: error?.data,
        });
        yield put({
            type: BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_RESET,
            payload: {},
        });
    }
}


export function* acctionBusinessEmail(): any {
    yield takeEvery(BusinessEmailActionTypes.BUSINESS_EMAIL_FIRST, businessEmailFunction);
}
export function* acctionBusinessVerifyEmail(): any {
    yield takeEvery(BusinessEmailActionTypes.BUSINESS_EMAIL_VERIFY_FIRST, businessEmailVerifyFunction);
}

function* businessEmailSaga(): any {
    yield all([
        fork(acctionBusinessEmail),
        fork(acctionBusinessVerifyEmail)
    ]);
}

export default businessEmailSaga;
