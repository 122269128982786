export const VatDetailsActionTypes = {
  GET_VAT_DETAILS_FIRST: "@@/layout/GET_VAT_DETAILS_FIRST",
  GET_VAT_DETAILS_LOADING: "@@/layout/GET_VAT_DETAILS_LOADING",
  GET_VAT_DETAILS_SUCCESS: "@@/layout/GET_VAT_DETAILS_SUCCESS",
  GET_VAT_DETAILS_ERROR: "@@/layout/GET_VAT_DETAILS_ERROR",

  ADD_VAT_DETAILS_FIRST: "@@/layout/ADD_VAT_DETAILS_FIRST",
  ADD_VAT_DETAILS_LOADING: "@@/layout/ADD_VAT_DETAILS_LOADING",
  ADD_VAT_DETAILS_SUCCESS: "@@/layout/ADD_VAT_DETAILS_SUCCESS",
  ADD_VAT_DETAILS_ERROR: "@@/layout/ADD_VAT_DETAILS_ERROR",
  ADD_VAT_DETAILS_RESET: "@@/layout/ADD_VAT_DETAILS_RESET",

  ADD_FINAL_VAT_DETAILS_FIRST: "@@/layout/ADD_FINAL_VAT_DETAILS_FIRST",
  ADD_FINAL_VAT_DETAILS_LOADING: "@@/layout/ADD_FINAL_VAT_DETAILS_LOADING",
  ADD_FINAL_VAT_DETAILS_SUCCESS: "@@/layout/ADD_FINAL_VAT_DETAILS_SUCCESS",
  ADD_FINAL_VAT_DETAILS_ERROR: "@@/layout/ADD_FINAL_VAT_DETAILS_ERROR",
  ADD_FINAL_VAT_DETAILS_RESET: "@@/layout/ADD_FINAL_VAT_DETAILS_RESET",
};
